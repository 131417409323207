import React from 'react';
import Footer from './Footer';
import Header from './Header';
import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="text-gray-900">{children}</main>
      <CookieConsent 
		enableDeclineButton 
		debug={false}
		flipButtons
		style={{ background: "#E0E0E0" }}		
		location="bottom"
        buttonText="Accepteer"
        buttonStyle=""
        buttonId=""
        buttonClasses="btn btn-warning"
        declineButtonText="Enkel functioneel"
        declineButtonClasses="btn btn-link btn-sm"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses=""
        cookieName="gatsby-gdpr-google-analytics">
        Deze website gebruikt cookies. De cookies zijn nodig voor het functioneren van de site. Wij respecteren uw Privacy. Voor meer info : check onze Privacy pagina. 
		</CookieConsent>
      <Footer />
    </>
  );
};

export default Layout;
